import React from 'react';

function StatsHeaderComponent() {
   return (
      <div className='stats-header-container bdr-radius '>
         <h2 className='stats-header-container__header '>Team statistics</h2>
      </div>
   );
}

export default StatsHeaderComponent;
