const datas = [
   '#',
   'Name',
   'Goals',
   'Succ. Dribbles',
   'Tackles',
   'Assists',
   'Acc. Passes%',
   'Rating',
];

export default datas;
