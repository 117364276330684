const datas = [
   ['Liverpool', 'Home', 'Away', 'All'],
   ['Games Played', '21', '10', '31'],
   ['Win', '21', '10', '31'],
   ['Draws', '21', '10', '31'],
   ['Loss', '21', '10', '31'],
   ['Goals For', '21', '10', '31'],
   ['Goals Against', '21', '10', '31'],
   ['Goals For(Avg)', '21', '10', '31'],
   ['Goals Against(Avg)', '21', '10', '31'],
];

export default datas;
