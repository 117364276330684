import './css/style.css';
import LandingPage from './pages/LandingPage';

// import stadium from '../icons/stadium.svg';

function App() {
   return (
      <div className='App'>
         <LandingPage />
      </div>
   );
}

export default App;
