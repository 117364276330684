const sportsArray = [
   'Football',
   'Tennis',
   'Baseball',
   'Hockey',
   'Handball',
   'Cricket',
   'Volleyball',
   'Table Tennis',
];
export default sportsArray;